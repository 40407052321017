import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from "@sentry/react";
import { createBrowserRouter, RouterProvider, Outlet, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import App from './App.tsx';
import Nav from './components/nav';
import Footer from './components/footer';
import Tos from './tos.tsx';
import TermsOfSale from './termofsale.tsx';
import Aup from './aup.tsx';
import Nup from './nup.tsx';
import Sla from './sla.tsx';
import Fup from './fup.tsx';

// Initialize Sentry with enhanced user tracking and trace sampling
Sentry.init({
  dsn: "https://8dc56d7378ef1b89ee2560917d39cf3a@o4506417064378368.ingest.us.sentry.io/4508078237876224",
  integrations: [
     Sentry.browserTracingIntegration({}),
    

    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren, // Add this line
      matchRoutes, // Add this line
    }),
  ],
  tracesSampleRate: 1.0,  // Adjust this based on your requirements
  replaysSessionSampleRate: 0.5,  // Adjust this to control how many sessions to record
  replaysOnErrorSampleRate: 1.0,  // Sample rate for recording sessions when an error occurs
  autoSessionTracking: true,
  release: "astroman-dev-v7", // Add your app version here
  environment: process.env.NODE_ENV || 'production', // Set to 'production' by default
  beforeSend(event) {
    // Check if it's an exception, and if so, show report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

// Layout Component with navigation and dynamic routing through <Outlet />
const Layout = () => {
  return (
    <>
      <Nav />
      <Outlet /> {/* Routed content */}
      <Footer />
    </>
  );
};

// Router setup
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <App /> },
      { path: "aup", element: <Aup /> },
      { path: "fup", element: <Fup /> },
      { path: "tos", element: <Tos /> },
      { path: "termofsale", element: <TermsOfSale /> },
      { path: "sla", element: <Sla /> },
      { path: "nup", element: <Nup /> },
    ],
  },
]);

// Wrap RouterProvider with Sentry's ErrorBoundary
const SentryRoutes = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  );
};

// Main application rendering
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <SentryRoutes />
  </React.StrictMode>
);

// Error fallback component to handle Sentry-bound errors
function ErrorFallback({ error, componentStack, eventId, resetError }: { error: unknown; componentStack: string; eventId: string; resetError: () => void }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error instanceof Error ? error.message : String(error)}</pre>
      <details>
        <summary>Stack trace</summary>
        <pre>{componentStack}</pre>
      </details>
      <button onClick={resetError}>Try again</button>
    </div>
  );
}

export default Sentry.withProfiler(App);